import { connector } from 'redux/utils/connector';
import CashbackApi from 'app/redux/modules/Cashback/api';
import TrustpilotSection from 'app/components/modules/Cashback/Trustpilot';

import { getCashbackRewievsRequest } from '../actions';
import { STORE_NAME } from 'redux/modules/Cashback/constants';

const mapStateToProps = (state) => ({
  reviews: CashbackApi.getTrustpilotReviews(state),
  isLoading: CashbackApi.isTrustpilotReviewsLoading(state),
  error: CashbackApi.trustpilotReviewsError(state),
});

export default connector(STORE_NAME, mapStateToProps, {
  fetchReviews: getCashbackRewievsRequest,
})(TrustpilotSection);
