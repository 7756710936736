import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { STORE_NAME, GET_SAVINGS_CALCULATION_REQUEST } from './constants';
import getSavings from './service/getSavings';

export function* combinedSagas() {
  yield takeLatest(GET_SAVINGS_CALCULATION_REQUEST, handleGetSavingsRequest);
}

export function* handleGetSavingsRequest() {
  try {
    const fields = yield select((store) =>
      store
        .get(STORE_NAME)
        .get('fields')
        .toJS()
    );
    const response = yield call(getSavings, fields);
    yield put(actions.requestComplete(response.data.savings));
  } catch (error) {
    yield put(actions.requestFailed(error));
  }
}
