import {
  ADD_CASHBACK_CARD_HOLDERS,
  CASHBACK_CARD_ADDED,
  ADD_CASHBACK_CARD,
  REMOVE_CASHBACK_CARD,
  GET_CASHBACK_REVIEWS_REQUEST,
  GET_CASHBACK_REVIEWS_SUCCESS,
  GET_CASHBACK_REVIEWS_FAILURE,
  GET_CASHBACK_RETAILERS_REQUEST,
  GET_CASHBACK_RETAILERS_SUCCESS,
  GET_CASHBACK_RETAILERS_FAILURE,
} from './constants';

export const addCashbackCard = () => ({
  type: ADD_CASHBACK_CARD,
});

export const cashbackCardAdded = () => ({
  type: CASHBACK_CARD_ADDED,
});

export const addHolders = (holders) => {
  return {
    type: ADD_CASHBACK_CARD_HOLDERS,
    holders,
  };
};

export const removeCashbackCard = () => ({
  type: REMOVE_CASHBACK_CARD,
});

export const getCashbackRewievsRequest = () => ({
  type: GET_CASHBACK_REVIEWS_REQUEST,
});
export const getCashbackRewievsSuccess = (response) => ({
  type: GET_CASHBACK_REVIEWS_SUCCESS,
  response,
});
export const getCashbackRewievsFailure = (error) => ({
  type: GET_CASHBACK_REVIEWS_FAILURE,
  error,
});

export const getCashbackRetailersRequest = () => ({
  type: GET_CASHBACK_RETAILERS_REQUEST,
});
export const getCashbackRetailersSuccess = (response) => ({
  type: GET_CASHBACK_RETAILERS_SUCCESS,
  response,
});
export const getCashbackRetailersFailure = (error) => ({
  type: GET_CASHBACK_RETAILERS_FAILURE,
  error,
});
