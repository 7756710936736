export const STORE_NAME = 'Cashback';

export const ADD_CASHBACK_CARD = 'ADD_CASHBACK_CARD';
export const CASHBACK_CARD_ADDED = 'CASHBACK_CARD_ADDED';
export const ADD_CASHBACK_CARD_HOLDERS = 'ADD_CASHBACK_CARD_HOLDERS';
export const REMOVE_CASHBACK_CARD = 'REMOVE_CASHBACK_CARD';
export const GET_CASHBACK_REVIEWS_REQUEST = 'GET_CASHBACK_REVIEWS_REQUEST';
export const GET_CASHBACK_REVIEWS_SUCCESS = 'GET_CASHBACK_REVIEWS_SUCCESS';
export const GET_CASHBACK_REVIEWS_FAILURE = 'GET_CASHBACK_REVIEWS_FAILURE';
export const GET_CASHBACK_RETAILERS_REQUEST = 'GET_CASHBACK_RETAILERS_REQUEST';
export const GET_CASHBACK_RETAILERS_SUCCESS = 'GET_CASHBACK_RETAILERS_SUCCESS';
export const GET_CASHBACK_RETAILERS_FAILURE = 'GET_CASHBACK_RETAILERS_FAILURE';

export const APPLICATION_FEE = {
  value: 500,
  exponent: 2,
  currency: 'GBP',
};

export const MONTHLY_FEE = {
  value: 300,
  exponent: 2,
  currency: 'GBP',
};
