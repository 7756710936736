import isRequestInProgress from 'redux/utils/isRequestInProgress';
import { STORE_NAME } from './constants';

class CashbackApi {
  static getHasApplied(state) {
    return state.get(STORE_NAME).toJS().appliedForCashback;
  }

  static isRequestInProgress(state) {
    return isRequestInProgress(state, STORE_NAME);
  }

  static getHolders(state) {
    return state.get(STORE_NAME).toJS().holders;
  }

  static getApplicationFee(state) {
    return state.get(STORE_NAME).toJS().applicationFee;
  }

  static getMonthlyFee(state) {
    return state.get(STORE_NAME).toJS().monthlyFee;
  }

  static getTrustpilotReviews(state) {
    return state.get(STORE_NAME).toJS().reviews.data;
  }

  static isTrustpilotReviewsLoading(state) {
    return state.get(STORE_NAME).toJS().reviews.loading;
  }

  static trustpilotReviewsError(state) {
    return state.get(STORE_NAME).toJS().reviews.error;
  }

  static getRetailers(state) {
    return state.get(STORE_NAME).toJS().retailers.data;
  }

  static isRetailersLoading(state) {
    return state.get(STORE_NAME).toJS().retailers.loading;
  }

  static retailersError(state) {
    return state.get(STORE_NAME).toJS().retailers.error;
  }

  static getIsAddingCashback(state) {
    return state.getIn([STORE_NAME, 'isAddingCashback']);
  }
}

export default CashbackApi;
