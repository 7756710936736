import axios from 'axios';
import { API_URL } from 'config';

export default (fields) => {
  const data = {};
  Object.keys(fields).forEach((key) => {
    if (fields[key]) data[key] = fields[key];
  });

  return axios.create().request({
    url: `${API_URL}/products/cashback/challenge`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data,
  });
};
