import {
  CBC_UPDATE_FIELD,
  GET_SAVINGS_CALCULATION_FAILURE,
  GET_SAVINGS_CALCULATION_REQUEST,
  GET_SAVINGS_CALCULATION_SUCCESS,
  CBC_SKIP,
} from './constants';

export const updateField = (name, value) => ({
  type: CBC_UPDATE_FIELD,
  name,
  value,
});
export const makeRequest = () => ({ type: GET_SAVINGS_CALCULATION_REQUEST });
export const requestComplete = (savings) => ({
  type: GET_SAVINGS_CALCULATION_SUCCESS,
  savings,
});
export const requestFailed = (error) => ({
  type: GET_SAVINGS_CALCULATION_FAILURE,
  error,
});
export const skip = () => ({ type: CBC_SKIP });
