import axios from 'axios';
import { TRUSTPILOT_API_KEY, TRUSTPILOT_BUSINESS_UNIT_ID } from 'config';

const STARS = 5;

const getCBCReviews = (numberOfReviews = 20) => {
  const url =
    'https://api.trustpilot.com/v1/business-units/' +
    TRUSTPILOT_BUSINESS_UNIT_ID +
    '/reviews?perPage=' +
    numberOfReviews +
    '&stars=' +
    STARS +
    '&tagValue=cashback';

  return axios.create().request({
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      apiKey: TRUSTPILOT_API_KEY,
    },
  });
};

export default getCBCReviews;
