import Immutable from 'immutable';
import {
  ADD_CASHBACK_CARD_HOLDERS,
  CASHBACK_CARD_ADDED,
  ADD_CASHBACK_CARD,
  GET_CASHBACK_REVIEWS_REQUEST,
  GET_CASHBACK_REVIEWS_SUCCESS,
  GET_CASHBACK_REVIEWS_FAILURE,
  GET_CASHBACK_RETAILERS_REQUEST,
  GET_CASHBACK_RETAILERS_SUCCESS,
  GET_CASHBACK_RETAILERS_FAILURE,
  REMOVE_CASHBACK_CARD,
  APPLICATION_FEE,
  MONTHLY_FEE,
} from './constants';

const initialState = Immutable.fromJS({
  appliedForCashback: false,
  applicationFee: null,
  monthlyFee: null,
  reviews: {
    data: [],
    loading: false,
    error: null,
  },
  retailers: {
    data: [],
    loading: false,
    error: null,
  },
  isAddingCashback: false,
});

export default function CashbackReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CASHBACK_CARD:
      return state.merge({
        appliedForCashback: true,
        applicationFee: APPLICATION_FEE,
        monthlyFee: MONTHLY_FEE,
        isAddingCashback: true,
      });

    case CASHBACK_CARD_ADDED:
      return state.merge({
        isAddingCashback: false,
      });

    case ADD_CASHBACK_CARD_HOLDERS:
      return state.mergeDeep({
        holders: action.holders,
      });

    case REMOVE_CASHBACK_CARD:
      return state.merge({
        appliedForCashback: false,
      });

    case GET_CASHBACK_REVIEWS_REQUEST:
      return state.mergeDeep({
        reviews: {
          loading: true,
          error: null,
        },
      });
    case GET_CASHBACK_REVIEWS_SUCCESS:
      return state.merge({
        reviews: {
          data: action.response,
          loading: false,
          error: null,
        },
      });
    case GET_CASHBACK_REVIEWS_FAILURE:
      return state.mergeDeep({
        reviews: {
          loading: false,
          error: action.error,
        },
      });

    case GET_CASHBACK_RETAILERS_REQUEST:
      return state.mergeDeep({
        retailers: {
          loading: true,
          error: null,
        },
      });
    case GET_CASHBACK_RETAILERS_SUCCESS:
      return state.merge({
        retailers: {
          data: action.response,
          loading: false,
          error: null,
        },
      });
    case GET_CASHBACK_RETAILERS_FAILURE:
      return state.mergeDeep({
        retailers: {
          loading: false,
          error: action.error,
        },
      });

    default:
      return state;
  }
}
