import Immutable from 'immutable';

import {
  CBC_UPDATE_FIELD,
  GET_SAVINGS_CALCULATION_SUCCESS,
  GET_SAVINGS_CALCULATION_REQUEST,
  GET_SAVINGS_CALCULATION_FAILURE,
} from './constants';

const initialState = Immutable.fromJS({
  fields: {
    grocery: 450,
    petrol: 150,
    clothes: 50,
    health: 50,
    household: 50,
    diy: 50,
    hotels: 100,
    holidays: 50,
    gifts: 50,
  },
  loading: false,
  error: null,
  savings: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case CBC_UPDATE_FIELD:
      return state.mergeDeep({ fields: { [action.name]: action.value } });

    case GET_SAVINGS_CALCULATION_SUCCESS:
      return state.mergeDeep({ savings: action.savings, loading: false });

    case GET_SAVINGS_CALCULATION_REQUEST:
      return state.mergeDeep({ loading: true, error: null });

    case GET_SAVINGS_CALCULATION_FAILURE:
      return state.mergeDeep({ loading: false, error: action.error });

    default:
      return state;
  }
}
