import { connector } from 'redux/utils/connector';
import Cashback from 'components/modules/Cashback';
import * as actionCreators from 'redux/modules';
import { STORE_NAME } from 'redux/modules/CBCChallenge/constants';
import { STORE_NAME as CBC_FORM_NAME } from 'redux/modules/Cashback/constants';
import { getCashbackRetailersRequest } from './actions';
import CashbackApi from './api';

export default connector(
  STORE_NAME,
  (state) => ({
    isLoading: CashbackApi.getIsAddingCashback(state),
  }),
  {
    removeCashbackCard: actionCreators[CBC_FORM_NAME].removeCashbackCard,
    addCashbackCard: actionCreators[CBC_FORM_NAME].addCashbackCard,
    fetchRetailers: getCashbackRetailersRequest,
  }
)(Cashback);
