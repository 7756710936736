export const STORE_NAME = 'CBCChallenge';

export const CBC_SKIP = 'CBC_SKIP';
export const CBC_UPDATE_FIELD = 'CBC_UPDATE_FIELD';
export const GET_SAVINGS_CALCULATION_SUCCESS =
  'GET_SAVINGS_CALCULATION_SUCCESS';
export const GET_SAVINGS_CALCULATION_REQUEST =
  'GET_SAVINGS_CALCULATION_REQUEST';
export const GET_SAVINGS_CALCULATION_FAILURE =
  'GET_SAVINGS_CALCULATION_FAILURE';
