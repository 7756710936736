import { takeLatest, put, call } from 'redux-saga/effects';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';

import * as actions from './actions';
import getCBCReviews from './getCBCReviews';
import getCBCRetailers from './getCBCRetailers';
import initCashback from './services/initCashback';
import removeCashback from './services/removeCashback';
import {
  GET_CASHBACK_REVIEWS_REQUEST,
  GET_CASHBACK_RETAILERS_REQUEST,
  ADD_CASHBACK_CARD,
  REMOVE_CASHBACK_CARD,
} from './constants';

function* handleGetCashbackRewievsRequest() {
  try {
    const result = yield call(getCBCReviews);
    yield put(actions.getCashbackRewievsSuccess(result?.data?.reviews));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_CASHBACK_REVIEWS_REQUEST);
    yield put(actions.getCashbackRewievsFailure(error));
  }
}

function* handleGetCashbackRetailersRequest() {
  try {
    const result = yield call(getCBCRetailers);
    yield put(actions.getCashbackRetailersSuccess(result?.data));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_CASHBACK_RETAILERS_REQUEST);
    yield put(actions.getCashbackRetailersFailure(error));
  }
}

function* handleAddCashbackCard() {
  try {
    yield call(initCashback);
  } catch (err) {
    Sentry.log(err, ERRORS.YOUR_DETAILS_SUBMIT_CBC);
  } finally {
    yield put(actions.cashbackCardAdded());
  }
}

function* handleRemoveCashbackCard() {
  yield call(removeCashback);
}

export function* combinedSagas() {
  yield takeLatest(
    GET_CASHBACK_REVIEWS_REQUEST,
    handleGetCashbackRewievsRequest
  );
  yield takeLatest(
    GET_CASHBACK_RETAILERS_REQUEST,
    handleGetCashbackRetailersRequest
  );
  yield takeLatest(ADD_CASHBACK_CARD, handleAddCashbackCard);
  yield takeLatest(REMOVE_CASHBACK_CARD, handleRemoveCashbackCard);
}
