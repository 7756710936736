import { connector } from 'redux/utils/connector';
import CashbackApi from 'app/redux/modules/Cashback/api';
import RetailersSection from 'app/components/modules/Cashback/Retailers';
import { STORE_NAME } from 'redux/modules/Cashback/constants';

const mapStateToProps = (state) => ({
  retailers: CashbackApi.getRetailers(state),
  isLoading: CashbackApi.isRetailersLoading(state),
  error: CashbackApi.retailersError(state),
});

export default connector(STORE_NAME, mapStateToProps)(RetailersSection);
